<template>
  <v-carousel
      :continuous="true"
      :cycle="true"
      :show-arrows="true"
      >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      :href="'https://www.youtube.com/watch?v=' + item.videoId"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        apiKey: 'AIzaSyCYKsacD640p4A_dcU5DNcXYG4I_r_Hy9E',
        items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
      }
    },
    
    methods:
    {

      parseThumbnails(data)
      {
        let items = []
        data.items.forEach( (item) => {
          let thumb = ( item.snippet.thumbnails.maxres || item.snippet.thumbnails.high ); 
          items.push({
            src: thumb.url,
            title: item.snippet.title,
            videoId: item.snippet.resourceId.videoId
          });
        });
        this.items = items;
        return items;
      },
      async getPlaylistInfo() {
        try {

            const response = await this.$http.get(
              "https://www.googleapis.com/youtube/v3/playlistItems",
              {
                params: {
                  playlistId: 'UUemdsZKI6DDswhD9lsWmorA',
                  key: this.apiKey,
                  maxResult: 50,
                  part: 'snippet'
                }
              }
            );
            console.log('GETPLAYLIST:');
            console.log(response.data);
            this.parseThumbnails(response.data)

        } catch (error) {
            console.log(error);
        }
      },
      async getChannelInfo() {
        try {
            const response = await this.$http.get(
              "https://www.googleapis.com/youtube/v3/channels",
              {
                params: {
                  id: 'UCemdsZKI6DDswhD9lsWmorA',
                  key: this.apiKey,
                  part: 'snippet,contentDetails,statistics,status'
                }
              }
            );
            console.log('GETCHANNELINFO');
            console.log(response.data);

        } catch (error) {
            console.log(error);
        }
      },
    },
    created() {
      this.getPlaylistInfo();
    },
  }
</script>