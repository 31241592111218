<template>
  <v-container>
    <v-row class="text-center">

      <v-col class="mb-4">  
        <MyCarousel />
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      > 
        <h1 class="display-2 font-weight-bold mb-3">
          На часок, по лайту...
        </h1>

        <p class="subheading font-weight-regular">
          Мы, команда энтузиастов полного привода и бездорожья ленинградской области,
          приветствуем Вас на нашем канале! Следите за нашими выездами,
          поддерживайте, участвуйте в них!
        </p>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import MyCarousel from './MyCarousel.vue'
  export default {
  components: { MyCarousel },
    name: 'MainPage',

    data: () => ({
    }),
  }
</script>
