<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
    >

      <div class="d-flex align-center">

        <v-img
          alt="Lite Offroad automobile club"
          class="shrink mr-2"
          contain
          :src="require('./assets/lo.jpg')"
          transition="scale-transition"
          width="190"
        />

      </div>

      <v-spacer></v-spacer>

      <v-btn
        :href="uTubeUrl"
        target="_blank"
        text
      >
        <span class="mr-2">ПЕРЕЙТИ НА КАНАЛ</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      
    </v-app-bar>

    <v-main>
      <MainPage/>
    </v-main>

  <v-footer
      app
      color="black"
      dark
  >

  <div class="d-flex align-center">
        <v-btn
          v-for="icon in icons"
          :key="icon[0]"
          class="mx-4"
          dark
          icon
          :href="icon[1]"
        >
          <v-icon size="24px">
            {{ icon[0] }}
          </v-icon>
        </v-btn>
     </div>
    
    <v-spacer></v-spacer>
    
    <span class="mr-2">
        <strong><b>@lite</b><span class="red--text">offroad</span></strong> team
        {{ new Date().getFullYear() }}
    </span>

 </v-footer>

  </v-app>
</template>

<script>

import MainPage from './components/MainPage';

export default {
  name: 'App',

  components: {
    MainPage
  },

  data: () => ({
    uTubeUrl: 'https://www.youtube.com/channel/UCemdsZKI6DDswhD9lsWmorA',
    icons: [
        [ 'fab fa-vk', 'https://vk.com/liteoffroad'],
        [ 'mdi-youtube',   'https://www.youtube.com/channel/UCemdsZKI6DDswhD9lsWmorA' ],
        [ 'mdi-instagram', 'https://instagram.com/liteoffroad?utm_medium=from_site' ],
    ],
  }),
};

/*
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
*/


</script>
